import React from "react";
import SaleReport from "../Admin/saleReport/SaleReport";

const OpertionalManager = () => {
  return (
    <div>
      <SaleReport />
    </div>
  );
};

export default OpertionalManager;
