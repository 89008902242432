import React, { useState, useEffect } from "react";
import "./salereport.css";
import { useAdminReport, usePartyAdminReport } from "../../../hooks/report";
import { useNavigate } from "react-router-dom";
import CashFlow from "../../../components/cashflow/CashFlow";
import DatePicker from "react-datepicker"; // Add this import
import "react-datepicker/dist/react-datepicker.css"; // Add this import for styles
import { ClipLoader } from "react-spinners";

const SaleReport = () => {
  const navigate = useNavigate();
  const [partyDetailsData, setPartyDetailsData] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');

  const handleClick = (item) => {
    navigate("/saledetails", { state: item });
  };

  const handlePartyDetailsClick = (item) => {
    navigate("/partydetailsreport", { state: item });
  };

  const { data, mutateAsync, isLoading } = useAdminReport();
  const {
    data: partyData,
    mutateAsync: partyAsync,
    isLoading: partyLoading,
  } = usePartyAdminReport();
  let totalCash = 0;
  let upi = 0;
  let upiascsv = 0;
  let rista = 0;
  let short = 0;
  let grandshort = 0;
  let totalGrandCash = 0;
  let partyshort = 0;
  useEffect(() => {
    setPartyDetailsData(partyData?.data);
  }, [partyData]);

  const handleopenmodal = () => {
    setShowmodal(true);
  };
  const handleCloseModal = () => {
    setShowmodal(false);
  };

  useEffect(() => {
    mutateAsync({ date: "" });
    partyAsync({ date: "" });
  }, [mutateAsync, partyAsync]); // Empty dependency array ensures this effect runs only once, on mount

  const handleDateSelection = (date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() - timezoneOffset);

    const formattedDate = `${adjustedDate.getFullYear()}-${String(
      adjustedDate.getMonth() + 1
    ).padStart(2, "0")}-${String(adjustedDate.getDate()).padStart(2, "0")}`;

    setSelectedDate(adjustedDate); // Set the selected date

    mutateAsync({ date: formattedDate });
    partyAsync({ date: formattedDate });
  };

  return (
    <div>
      {showmodal && <CashFlow handleCloseModal={handleCloseModal} data={data}/>}
      <div className="salereport">
        <div className="salereportwrapper">
          <div className="salereportheading">
            <h3>Latest Sale Report</h3>
          </div>
          <div className="button">
            <DatePicker
              className="btt"
              selected={selectedDate}
              onChange={(date) => handleDateSelection(date)}
              dateFormat="yyyy-MM-dd" // Example date format
              placeholderText="Choose Date" // Placeholder text
            />
          </div>

          {isLoading || partyLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <ClipLoader size={100} />
            </div>
          ) : (
            <>
              <div className="salereportcontainer">
                <div className="latestsalereport">
                  <div className="latestsalereportwrapper">
                    <table>
                      <tr>
                        <th>C. Name</th>
                        <th>Emp. Name </th>
                        <th>Rista</th>
                        <th>Cash</th>
                        <th>Upi</th>
                        <th>Upi a/c csv</th>
                        <th>Excess/Short</th>
                        <th>Total</th>
                      </tr>
                      {data?.data?.map((item, index) => {
                        const ristaAmount =
                          item?.result?.[0]?.jsonData?.[0]?.RistaAmount || 0;
                        rista += ristaAmount;

                        const upicsv =
                          item?.result?.[0]?.jsonData?.[0]?.UpiAmount || 0;
                        upiascsv += upicsv;

                        const cashAmount = item?.documents?.reduce(
                          (total, cashdetails) => {
                            return (
                              total + (cashdetails?.cashFlowDetails?.cash || 0)
                            );
                          },
                          0
                        );
                        totalCash += cashAmount;

                        const upipos = item?.documents?.reduce(
                          (total, cashdetails) => {
                            return (
                              total + (cashdetails?.cashFlowDetails?.upi || 0)
                            );
                          },
                          0
                        );
                        upi += upipos;

                        const grandtotal = item?.documents?.reduce(
                          (total, cashdetails) => {
                            return (
                              total +
                              (cashdetails?.cashFlowDetails?.totalCash || 0)
                            );
                          },
                          0
                        );
                        totalGrandCash += grandtotal;

                        grandshort =  totalGrandCash - rista;

                        const totalCashes = item?.documents?.reduce(
                          (total, cashdetails) => {
                            return (
                              total +
                              (cashdetails?.cashFlowDetails?.totalCash || 0)
                            );
                          },
                          0
                        );
                        short =  totalCashes - ristaAmount ; // Calculate excess for each row

                        return (
                          <tr
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() => handleClick(item)}
                          >
                            <td>{item?._id?.counterName}</td>

                            <td style={{ padding: "0px" }}>
                              {item?.documents?.map((cashdetails, index3) => {
                                return (
                                  <div
                                    key={index3}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <td style={{ border: "unset" }}>
                                      {cashdetails?.posname || 0}
                                    </td>
                                  </div>
                                );
                              })}
                            </td>

                            <td>
                              {item?.result?.[0]?.jsonData?.[0]?.RistaAmount}
                            </td>
                            <td style={{ padding: "0px" }}>
                              {item?.documents?.map((cashdetails, index3) => {
                                return (
                                  <div
                                    key={index3}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <td style={{ border: "unset" }}>
                                      {cashdetails?.cashFlowDetails?.cash || 0}
                                    </td>
                                  </div>
                                );
                              })}
                            </td>
                            <td style={{ padding: "0px" }}>
                              {item?.documents?.map(
                                (cashdetailsupi, index4) => {
                                  return (
                                    <div
                                      key={index4}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <td style={{ border: "unset" }}>
                                        {cashdetailsupi?.cashFlowDetails?.upi ||
                                          0}
                                      </td>
                                    </div>
                                  );
                                }
                              )}
                            </td>
                            <td>
                              {item?.result?.[0]?.jsonData?.[0]?.UpiAmount}
                            </td>
                            <td>{short.toFixed(2)}</td>
                            <td style={{ padding: "0px" }}>
                              {item?.documents?.map(
                                (cashdetailsupi, index4) => {
                                  return (
                                    <div
                                      key={index4}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <td style={{ border: "unset" }}>
                                        {cashdetailsupi?.cashFlowDetails
                                          ?.totalCash || 0}
                                      </td>
                                    </div>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td>Total</td>
                        <td> </td>
                        <td>{rista.toFixed(2)}</td>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {totalCash}{" "}
                          <img
                            src="/assest/money.svg"
                            alt=""
                            style={{
                              height: "25px",
                              width: "25px",
                              cursor: "pointer",
                            }}
                            onClick={handleopenmodal}
                          />
                        </td>
                        <td>{upi}</td>
                        <td>{upiascsv}</td>
                        <td>{grandshort.toFixed(2)}</td>
                        <td>{totalGrandCash}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <h3 className="party-heading"> Party DSR Latest Report:</h3>
              <div className="salereportcontainer">
                <div className="latestsalereport">
                  <div className="latestsalereportwrapper">
                    <div className="partydetails">
                      <table>
                        <tr>
                          <th>C Name</th>
                          <th>Emp. Name </th>
                          <th>Party Name</th>
                          <th>F.Amt a/c mng.</th>
                          <th>Total Amt</th>
                          <th>Upi</th>
                          {/* <th>Online</th> */}
                          <th>Excess/Short</th>
                        </tr>

                        {partyDetailsData?.map((party, index1) => {
                          let partytotal = 0;

                          party.documents.forEach((document) => {
                            document.partyDetails.forEach((partyDetail) => {
                              partytotal += partyDetail.ptotalCash || 0;
                            });
                          });
                          // Calculate partyshort
                          const finalamountmng = party.partyDetails.reduce(
                            (total, partyitem) => {
                              return total + (partyitem?.finalAmountPaid || 0);
                            },
                            0
                          );
                          partyshort = finalamountmng - partytotal;
                          return (
                            // party?.self?.length > 0 && (
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() => handlePartyDetailsClick(party)}
                            >
                              <td>{party?._id?.counterName}</td>
                              <td style={{}}>
                                {party?.documents.map((partyitem, index5) => {
                                  return (
                                    <td
                                      style={{
                                        border: "unset",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {" "}
                                      {partyitem?.posname}{" "}
                                    </td>
                                  );
                                })}
                              </td>
                              <td style={{}}>
                                {party.documents?.[0]?.partyDetails?.map(
                                  (partyitems, index6) => {
                                    return (
                                      <td
                                        style={{
                                          border: "unset",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {partyitems?.partyName}
                                      </td>
                                    );
                                  }
                                )}
                              </td>
                              <td>
                                {party?.partyDetails?.length > 0 ? (
                                  party?.partyDetails?.map(
                                    (partyitem, index2) => {
                                      return (
                                        <td
                                          style={{
                                            border: "unset",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          {partyitem?.finalAmountPaid
                                            ? partyitem?.finalAmountPaid
                                            : 0}
                                        </td>
                                      );
                                    }
                                  )
                                ) : (
                                  <td> no Data</td>
                                )}
                              </td>

                              <td style={{}}>
                                {party.documents?.[0]?.partyDetails?.map(
                                  (partyitems, index6) => {
                                    return (
                                      <td
                                        style={{
                                          border: "unset",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {partyitems?.ptotalCash}
                                      </td>
                                    );
                                  }
                                )}
                              </td>
                              <td style={{}}>
                                {party.documents?.[0]?.partyDetails?.map(
                                  (partyitems, index6) => {
                                    return (
                                      <td
                                        style={{
                                          border: "unset",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {partyitems?.pupi}
                                      </td>
                                    );
                                  }
                                )}
                              </td>

                              <td>{partyshort}</td>
                            </tr>
                          );
                          // );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaleReport;
