import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import Admin from "./Pages/Admin/Admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OpertionalManager from "./Pages/opertionalmanager/OpertionalManager";
// import PointOfSale from './Pages/PointOfSale';
import AddEmployee from "./Pages/Admin/addemployee/AddEmployee";

import ForgetPassword2 from "./components/Forgetpassword/ForgetPassword2";
import Forgetpassword1 from "./components/Forgetpassword/Forgetpassword1";
import Forgetpassword3 from "./components/Forgetpassword/Forgetpassword3";
import AddReport from "./Pages/pointofsale/AddReport";
import FinancialManager from "./Pages/finicialmanager/FinancialManager";
// import SaleReport from "./Pages/Admin/saleReport/SaleReport";
import Partdetails from "./components/partyDetails/Partdetails";
import Index from "./Pages/pointofsale/Index";
import UploadFile from "./Pages/Admin/uploadfile/UploadFile";
// import SaleDetails from "./Pages/Admin/saleReport/SaleDetails";
import AddReportNonTicket from "./Pages/pointofsale/AddReportNonTicket";
import Navbar from "./components/Navbar/Navbar";
import PartyDetailsReport from "./Pages/Admin/saleReport/PartyDetailsReport";
import AddCounter from "./Pages/Admin/addcounter/AddCounter";
import CounterDetails from "./Pages/Admin/addcounter/CounterDetails";
import BandColour from "./Pages/Admin/bandcolour/BandColour";
import EditNonTicketCollector from "./Pages/Admin/editdsl/EditNonTicketCollector";
import EditTicketCollector from "./Pages/Admin/editdsl/EditTicketCollector";
import EditPartyDetails from "./Pages/Admin/editdsl/EditPartyDetails";
import Dashboard from "./Pages/Admin/dashboard/Dashboard";
import ComboType from "./Pages/Admin/combotype/ComboType";
import SaleReportDublicate from "./Pages/Admin/saleReport/SaleReportDublicate";
import SaleDetailsDublicate from "./Pages/Admin/saleReport/SaleDetailsDublicate";
import Posaddfrom from "./Pages/Admin/addemployee/AddEmployeeForm";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgetpassword1" element={<Forgetpassword1 />} />
          <Route exact path="/forgetpassword2" element={<ForgetPassword2 />} />
          <Route exact path="/forgetpassword3" element={<Forgetpassword3 />} />
          {/* <Route exact path="/pointofsale" element={<PointOfSale/>} /> */}
          <Route exact path="/addreport" element={<AddReport />} />
          <Route
            exact
            path="/addreportnonticket"
            element={<AddReportNonTicket />}
          />

          <Route
            exact
            path="/financialmanager"
            element={<FinancialManager />}
          />
          <Route
            exact
            path="/opertionalmanager"
            element={<OpertionalManager />}
          />
          <Route exact path="/index" element={<Index />} />

          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/employee" element={<AddEmployee />} />
          <Route exact path="/employee/addemployee" element={<Posaddfrom/>} />
          {/* <Route exact path="/salereport" element={<SaleReport />} /> */}
          <Route exact path="/salereport" element={<SaleReportDublicate />} />

          <Route exact path="/partydetails" element={<Partdetails />} />
          <Route exact path="/uploadfile" element={<UploadFile />} />
          {/* <Route exact path="/saledetails" element={<SaleDetails />} /> */}
          <Route exact path="/saledetails" element={<SaleDetailsDublicate />} />

          <Route
            exact
            path="/partydetailsreport"
            element={<PartyDetailsReport />}
          />
          <Route exact path="/counterdetails/addcounter" element={<AddCounter />} />
          <Route exact path="/counterdetails" element={<CounterDetails />} />
          <Route exact path="/bandcolour" element={<BandColour />} />
          <Route exact path="/combotype" element={<ComboType/>} />

          <Route
            exact
            path="/editnonticketcollector"
            element={<EditNonTicketCollector />}
          />
          <Route
            exact
            path="/editticketcollector"
            element={<EditTicketCollector />}
          />
          <Route
            exact
            path="/editpartydetails"
            element={<EditPartyDetails />}
          />
           <Route
            exact
            path="/dashboard"
            element={<Dashboard/>}
          />
        </Routes>

        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
