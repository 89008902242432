import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import "../bandcolour/bandcolour.css";
import { toast } from "react-toastify";
import { initialComboType, comboTypeSchema } from "../../../validations";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ComboTypeItem from "./ComboTypeItem";
import { useComboType, useComboTypeDetails } from "../../../hooks/coboType";

const ComboType = () => {
    const navigate = useNavigate();
    const [refreshComboDetails, setRefreshComboDetails] = useState(false);
  
    const { data: combotypeDetails, refetch: refetchComboDetails } = useComboTypeDetails();
    const {
      mutateAsync: muteaddbandcolour,
      isLoading,
      isSuccess,
      isError,
      data: addcombotype,
      error,
    } = useComboType();
  
   
  
    useEffect(() => {
      if (refreshComboDetails) {
        // Reload data logic here
        // For example, refetch data using the useCounterName hook
        refetchComboDetails();
        setRefreshComboDetails(false);
      }
    }, [refreshComboDetails, refetchComboDetails]);
  
    const {
      resetForm,
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      handleBlur,
    } = useFormik({
      initialValues: initialComboType,
      validationSchema: comboTypeSchema,
      onSubmit: async (values) => {
        try {
         await muteaddbandcolour(values);
        } catch (error) {
          console.error("Error during conter Addition:", error);
        }
      },
    });

    useEffect(() => {
        if (isSuccess) {
          const { status, message } = addcombotype;
          toast.success(message);
    
          if (status === 200) {
            resetForm();
            refetchComboDetails();
          }
        }
    
        if (isError) {
          toast.error(error.response.data.message);
          if (error?.response?.data?.status === 401) {
            navigate("/");
          }
        }
      }, [isError, isSuccess, addcombotype, navigate,error, refetchComboDetails , resetForm]);
    return (
      <div>
        <div className="uploaddetails">
          <div className="uploadwrappers">
            <div className="heading">
              <p>Add Combo Tpe</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="fileinput">
                <div className="label">
                  <label>Combo Type</label>
                  <label style={{ marginRight: "10px" }}>:</label>
                </div>
                <div className="inputtype">
                  <input
                    type="text"
                    placeholder="Enter Combo Type"
                    name="ticketComboType"
                    value={values.ticketComboType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.ticketComboType && touched.ticketComboType && (
                    <div className="error-message">{errors.ticketComboType}</div>
                  )}
                </div>
              </div>
  
              <div className="button">
                <button type="submit" className="btt">
                  {isLoading ? (
                    <BeatLoader size={8} color={"#ffffff"} />
                  ) : (
                    "Submit Now"
                  )}
                </button>
              </div>
            </form>
          </div>
  
          <div className="uploadwrapper">
            <div className="contercontent">
              <div className="heading">
                <p>Combo Type:-</p>
              </div>
            </div>
  
            <div className="counteritem">
              {combotypeDetails?.data?.data?.map((item, index) => {
                return (
                  <div className="colcounter" key={index}>
                    <ComboTypeItem
                      combotype={item?.ticketComboType}
                      id={item?._id}
                      onDeleteSuccess={() => setRefreshComboDetails(true)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ComboType
