import React, { useEffect } from "react";
import "./updateEmployee.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
  initialValuesupdateEmployeeDetails,
  updateEmployeeSchema,
} from "../../../validations";
import { useUpdateEmployee } from "../../../hooks/addemployee";

const UpdateEmployee = ({ isOpen, onClose, id, onUpdateSuccess }) => {
  const navigate = useNavigate();

  const { mutateAsync, isLoading, isSuccess, isError, data, error } =
    useUpdateEmployee();

  const {
    resetForm,
    values,
    handleBlur,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValuesupdateEmployeeDetails,
    validationSchema: updateEmployeeSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync({ id, values });

        toast.success("Employee added successfully!");
        onUpdateSuccess();
        onClose();
      } catch (error) {
        console.error("Error during login:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status } = data;

      if (status === 200) {
        resetForm();
      }
    }

    if (isError) {
      toast.error("Something went wrong");
      if (error?.response?.data?.status === 401) {
        navigate("/");
      }
    }
  }, [isError, isSuccess, data, error, resetForm, navigate]);

  return (
    //   <div className="modal-overlay" onClick={onClose}>
    //   <div className="modal-content" onClick={(e) => e.stopPropagation()}>

    <div className="addemployeewrapper">
      <div className="loginformwraper" onClick={(e) => e.stopPropagation()}>
        <button
          type="button"
          className="cancelbtt"
          onClick={onClose}
          style={{
            background: "none",
            border: "none",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <i
            className="fa-solid fa-xmark fa-xl"
            style={{ color: "var(--secondary-text-color)", cursor: "pointer" }}
          ></i>
        </button>
        <h2 className="title">Update Password</h2>

        <form onSubmit={handleSubmit}>
          {/* <div className="inputfield">
          <label>User Name*</label>
          <input
            type="text"
            placeholder="Add Username"
            value={values.userName}
            name="userName"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.userName && errors.userName && (
            <div className="error-message">{errors.userName}</div>
          )}
        </div> */}
          {/* <div className="inputfield">
          <label>Name*</label>
          <input
            type="text"
            placeholder="Add Name"
            name="name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.name && touched.name && (
            <div className="error-message">{errors.name}</div>
          )}
        </div> */}
          {/* <div className="inputfield">
          <label>Phone Number*</label>
          <input
            type="text"
            placeholder="Add Phone Number"
            name="phone"
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.phone && touched.phone && (
            <div className="error-message">{errors.phone}</div>
          )}
        </div> */}
          <div className="inputfield">
            <label>Password*</label>
            <input
              type="text"
              placeholder="Add Password"
              name="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.password && touched.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </div>
          {/* <div className="inputfield">
          <label>Employee Type*</label>
          <select
            name="employeetype"
            value={values.employeetype}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="selected">Select Employee Type</option>
            <option value="POS Employee">POS Employee</option>
            <option value="Opertional Manager">Opertional Manager</option>
            <option value="Financial Manager">Financial Manager</option>
          </select>
          {errors.employeetype && touched.employeetype && (
            <div className="error-message">{errors.employeetype}</div>
          )}
        </div> */}

          <div>
            <button className="btt" type="submit">
              {isLoading ? (
                <BeatLoader size={8} color={"#ffffff"} />
              ) : (
                "Update Password"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>

    //   </div>
    // </div>
  );
};

export default UpdateEmployee;
