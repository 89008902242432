import React, { useEffect, useState } from "react";
import "./AddEmployee.css";
import { useNavigate } from "react-router-dom";
import EmployeeCard from "./EmployeeCard";
import { useEmployeeList } from "../../../hooks/addemployee";
import UpdateEmployee from "./UpdateEmployee";

const AddEmployee = () => {
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);
  const [filter, setFilter] = useState(""); // State for managing the filter
  const { data, refetch } = useEmployeeList(filter);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null); // State to manage the selected employee

  const handleEmployee = () => {
    navigate("/employee/addemployee");
  };

  useEffect(() => {
    if (reloadData) {
      refetch();
      setReloadData(false);
    }
  }, [reloadData, refetch]);

  const handleFilterChange = (newFilter) => {
    console.log("🚀 ~ handleFilterChange ~ newFilter:", newFilter);
    setFilter(newFilter);
    setReloadData(true); // Trigger data reload
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="employeedetails">
        <div className="employeewrapper">
          <h2 className="employeeHeading">Employee Details</h2>

          <div className="contercontent">
            <div className="heading">
              <p>Filter:-</p>
              <button
                className={`btt ${filter === "" ? "selected" : ""}`}
                onClick={() => handleFilterChange("")}
              >
                All
              </button>
              <button
                className={`btt ${filter === "Pos Employee" ? "selected" : ""}`}
                onClick={() => handleFilterChange("Pos Employee")}
              >
                Pos Employee
              </button>
              <button
                className={`btt ${
                  filter === "Operational Manager" ? "selected" : ""
                }`}
                onClick={() => handleFilterChange("Opertional Manager")}
              >
                Operational Manager
              </button>
              <button
                className={`btt ${
                  filter === "Financial Manager" ? "selected" : ""
                }`}
                onClick={() => handleFilterChange("Financial Manager")}
              >
                Financial Manager
              </button>
            </div>
            <div className="counterbtt">
              <button className="btt" onClick={handleEmployee}>
                Add Employee
              </button>
            </div>
          </div>

          <div className="counteritem">
            {data?.data?.data?.map((item, index) => {
              return (
                <div className="colcounter" key={index}>
                  <EmployeeCard
                    items={item}
                    onDeleteSuccess={() => setReloadData(true)}
                    onEditClick={(employee) => {
                      setSelectedEmployee(employee);
                      setModalOpen(true);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isModalOpen && selectedEmployee && (
        <UpdateEmployee
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          id={selectedEmployee?._id}
          onUpdateSuccess={() => {
            setModalOpen(false);
            setReloadData(true); // Trigger refetch on success

            // Optionally trigger a refetch or update parent state
          }}
        />
      )}
    </div>
  );
};

export default AddEmployee;
