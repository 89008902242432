import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./saledetails.css";
import { usePartyDetailsBYId } from "../../../hooks/partydetails";

const PartyDetailsReport = () => {
  const navigate = useNavigate();

  const { mutateAsync} = usePartyDetailsBYId();
  const location = useLocation();
  const data = location.state;
  const calculateCurrencyTotal = (notes, valuePerNote) => {
    return notes * valuePerNote;
  };

  const handleGuestList = (file) => {
    window.location.href = `https://eod.soluperts.com/uploads/${file}`;
  };
  const handlePartyDetailsEdit = async (id) => {
    const { data, error } = await mutateAsync(id);
    if (error) {
      console.error("Error fetching party details:", error);
    } else {
      navigate("/editpartydetails", { state: data });
    }
  };

  return (
    <div>
      <div className="saledetails">
        <div className="content">
          <div className="contentwrapper">
            <div className="cashflow">
              <h2>Party DSR a/c to Pos:-</h2>
              <table>
                <tbody>
                  <tr>
                    <th>Currency Type</th>
                    {data?.documents?.map((itemcash, index) => (
                      <React.Fragment key={index}>
                        {itemcash?.partyDetails?.map((partyitem, index1) => (
                          <React.Fragment key={index1}>
                            <th>No. of Notes</th>
                            <th>Total</th>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>

                  {[500, 200, 100, 50, 20, 10, 5, 2, 1].map(
                    (currency, index) => (
                      <tr key={index}>
                        <td>{currency}</td>
                        {data?.documents?.map((itemcash, index) => (
                          <React.Fragment key={index}>
                            {itemcash?.partyDetails?.map(
                              (partyitem, index1) => (
                                <React.Fragment key={index1}>
                                  <td>{partyitem[`pnotes${currency}`]}</td>
                                  <td>
                                    {calculateCurrencyTotal(
                                      partyitem[`pnotes${currency}`],
                                      currency
                                    ) || 0}
                                  </td>
                                </React.Fragment>
                              )
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    )
                  )}

                  <tr>
                    <td>Cash</td>
                    {data?.documents?.map((itemcash, index) => (
                      <React.Fragment key={index}>
                        {itemcash?.partyDetails?.map((partyitem, index1) => (
                          <React.Fragment key={index1}>
                            <td></td>
                            <td>{partyitem?.pcash || 0}</td>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Upi</td>
                    {data?.documents?.map((itemcash, index) => (
                      <React.Fragment key={index}>
                        {itemcash?.partyDetails?.map((partyitem, index1) => (
                          <React.Fragment key={index1}>
                            <td></td>
                            <td>{partyitem?.pupi || 0}</td>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Rozarpay</td>
                    {data?.documents?.map((itemcash, index) => (
                      <React.Fragment key={index}>
                        {itemcash?.partyDetails?.map((partyitem, index1) => (
                          <React.Fragment key={index1}>
                            <td></td>
                            <td>{partyitem?.prazorpay || 0}</td>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>

                  <tr>
                    <td>Grand Total</td>
                    {data?.documents?.map((itemcash, index) => (
                      <React.Fragment key={index}>
                        {itemcash?.partyDetails?.map((partyitem, index1) => (
                          <React.Fragment key={index1}>
                            <td></td>
                            <td>{partyitem?.ptotalCash || 0}</td>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="ticketdetails">
              <h2> Party DSR a/c to Admin</h2>
              {data?.partyDetails?.length > 0 ? (
                <table>
                  <tr>
                    <th>title</th>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <th
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Des{" "}
                          <i
                            className="fa-solid fa-pen-to-square"
                            onClick={() => {
                              handlePartyDetailsEdit(itemcash?._id);
                            }}
                          ></i>
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.email}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Event Booked By</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td style={{ fontWeight: "500", fontSize: "18px" }}>
                          {itemcash?.eventBookBy}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Date of Event</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.dateOfEvent}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Type of Event</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.typeOfEvent}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Designated Area For Event</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.designatedArea}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Name of Customer/School</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.nameOfCustomer}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Name of TA/Vendor</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.nameOfVendor}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.phone}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Zipline]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesZipline}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Zipcycle]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesZipcycle}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Bull Ride]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesBullRide}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Body Zorbing]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesBodyZorbing}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Laser Tag]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesLaserTag}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Magic Show]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesMagicShow}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Ballon Art]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesBallonArt}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>
                      Activities Included In the Package[Tattoo Paintaing]
                    </td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesTattooPaintaing}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Pottery]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesPottery}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[DJ]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesDJ}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Activities Included In the Package[Bartender]</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.activitiesBartender}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>No of Adults</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.noAdults}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>No of Kids</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.noKids}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Adult Price</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.adultPrice}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Kid Price</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.kidsPrice}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Any other Services</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.otherService}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Total Billing Amount</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.totalBillingAmount}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Date of Advance</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.DateOfAdvance}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Advance Amount</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.advanceAmount}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Mode of Payment</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.ModeOFAdvancePayment}</td>
                      </React.Fragment>
                    ))}
                  </tr>

                  <tr>
                    <td>Final Amount Paid</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.finalAmountPaid}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Mode of Payment</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.modeOfFinalPayment}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Discount</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.discount}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Describe Service Issues</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.serviceIssues}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Event Coordinator</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.eventCoordinator}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Final Payment Collected By</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.paymentCollectedBy}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Staff Available in Party</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td>{itemcash?.staffAvailableInParty}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                  <tr>
                    <td>Guest List</td>
                    {data?.partyDetails?.map((itemcash, indexes) => (
                      <React.Fragment key={indexes}>
                        <td
                          style={{ color: "#0000FF", cursor: "pointer" }}
                          onClick={() => {
                            handleGuestList(itemcash?.UplaoadGuestList);
                          }}
                        >
                          {" "}
                          {itemcash?.UplaoadGuestList.replace(/^\d+-/, "")}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                </table>
              ) : (
                <div className="follow-loading-div">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60vh",
                      color: "var(--secondary-text-color)",
                      fontFamily: "Inter",
                    }}
                  >
                    <img
                      src="/assest/noComments.svg"
                      alt="comments"
                      style={{
                        width: "123px",
                        height: "101px",
                      }}
                    />
                    <h1
                      style={{
                        lineHeight: "21px",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      Party DSR was not added.
                    </h1>
                    <span style={{ fontSize: "20px", fontWeight: "300" }}>
                      When Party DSR Added they’ll show here.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyDetailsReport;
