import React, { useState } from "react";
import "./saledetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyCounterName } from "../../../hooks/counter";

const SaleDetailsDublicate = () => {
  const nav = useNavigate();
  const location = useLocation();
  const data = location.state;
  console.log("🚀 ~ SaleDetailsDublicate ~ data:", data)
  const calculateCurrencyTotal = (notes, valuePerNote) => {
    return notes * valuePerNote;
  };
  // eslint-disable-next-line
  const [counter, setCounter] = useState(null);

  const employeeType = localStorage.getItem("employeetype");

  const { mutateAsync } = useVerifyCounterName();

  const handleEdit = (Counter, datas) => {
    setCounter(Counter);
    mutateAsync({ counterName: Counter })
      .then((response) => {
        const counterType = response.data.counterType;
        let editRoute = "/editticketcollector";
        if (counterType !== "ticketcollector") {
          editRoute = "/editnonticketcollector";
        }
        nav(editRoute, { state: datas });
      })
      .catch((error) => {
        console.error("API call failed:", error);
        // Handle error
      });
  };
  return (
    <div>
      <div className="saledetails">
        <div className="content">
          <div className="contentwrapper">
            <div className="button">
              <button
                className="btt"
                onClick={() => {
                  handleEdit(data?._id?.counterName, data);
                }}
                style={{
                  display:
                    employeeType === "Financial Manager" ? "none" : "block",
                    cursor: data?.documents?.length < 1 ? "not-allowed" : "pointer",
                    opacity: data?.documents?.length < 1 ? 0.6 : 1,          
                      }}
                disabled={data?.documents?.length < 1}
              >
                {" "}
                Edit
              </button>
            </div>

            {data?.documents?.[0]?.ticketDetails?.length > 0 && (
              <div className="ticketdetails">
                <h2> Ticket details:- </h2>

                <table>
                  <tr>
                    {/* <th>Emp. Name</th> */}
                    <th>Combo Type</th>
                    <th>Band Colour</th>
                    <th>Opening</th>
                    <th>Closing</th>
                    <th>Damage</th>
                    <th>Ext. Ticket</th>
                    <th>Compliment</th>
                    <th>Compliment By</th>

                    {/* <th>Annual Pass</th> */}
                    <th>Used Bands</th>
                    <th>Total Bands</th>
                  </tr>
                  {data?.documents?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item?.ticketDetails?.length > 0 ? (
                        item?.ticketDetails.map((ticket, ticketIndex) => (
                          <tr key={ticketIndex}>
                            {/* <td> {item?.posname}</td> */}
                            <td>{ticket?.comboType}</td>

                            <td>
                              {ticket?.bandColour.charAt(0).toUpperCase() +
                                ticket?.bandColour.slice(1)}
                            </td>
                            <td>{ticket?.ticketOpeningNo}</td>
                            <td>{ticket?.ticketClosingNo}</td>
                            <td>{ticket?.damage}</td>
                            <td>{ticket?.extendedTicket}</td>
                            <td>{ticket?.totalComplimentary}</td>
                            <td>{ticket?.complimentBy}</td>

                            {/* <td>{ticket?.annualPass}</td> */}
                            <td>{ticket?.totalUsedBands}</td>
                            <td>{ticket?.totalBands}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">No data</td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </table>
              </div>
            )}
            <div className="saledetailsreports">
              <div className="cashflow">
                <h2>
                  Cash flow Details:-
                  {data?.documents?.map((itemcash, index) => {
                    return (
                      <span style={{ fontWeight: 400 }}>
                        {itemcash?.posname}
                        {", "}
                      </span>
                    );
                  })}
                </h2>
                <table>
                  <tr>
                    <th>Currency Type</th>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <th>No. of Notes </th>
                          <th>Total</th>
                        </>
                      );
                    })}
                  </tr>

                  <tr>
                    <td>500</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes500}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes500,
                              500
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>200</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes200}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes200,
                              200
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>100</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes100}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes100,
                              100
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>50</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes50}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes50,
                              50
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>20</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes20}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes20,
                              20
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>10</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes10}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes10,
                              10
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>5</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes5}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes5,
                              5
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>2</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes2}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes2,
                              2
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>1</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td>{itemcash?.cashFlowDetails?.notes1}</td>
                          <td>
                            {calculateCurrencyTotal(
                              itemcash?.cashFlowDetails?.notes1,
                              1
                            ) || 0}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Cash</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td></td>
                          <td>{itemcash?.cashFlowDetails?.cash}</td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Upi</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td></td>
                          <td>{itemcash?.cashFlowDetails?.upi}</td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Razorpay</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td></td>
                          <td>{itemcash?.cashFlowDetails?.razorpay || 0}</td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>voucher</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td></td>
                          <td>{itemcash?.cashFlowDetails?.voucher || 0}</td>
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Grand Total</td>
                    {data?.documents?.map((itemcash, index) => {
                      return (
                        <>
                          <td></td>
                          <td>{itemcash?.cashFlowDetails?.totalCash}</td>
                        </>
                      );
                    })}
                  </tr>
                </table>
              </div>

              {data?.documents?.[0]?.onlineDeliveryOrder.length > 0 && (
                <div className="partydetails">
                  <h2>Online Delivery Order:-</h2>
                  <table>
                    <tr>
                      <th>Invoice</th>

                      <th>Amount</th>
                      <th>Aggregator </th>
                      <th>Remarks</th>
                    </tr>

                    {data?.documents?.[0]?.onlineDeliveryOrder?.map(
                      (online, indexes) => {
                        return (
                          <tr key={indexes}>
                            <td>{online?.invoice}</td>
                            <td>{online?.amount}</td>
                            <td>{online?.aggregator}</td>
                            <td>{online?.remarks}</td>
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
              )}
            </div>

            {data?.documents?.[0]?.complimentaryDetails?.length > 0 && (
              <div className="ticketdetails">
                <h2> Complimentary Details:- </h2>

                <table>
                  <tr>
                    <th>Name</th>
                    <th>Items</th>
                    <th>Quantity</th>
                    <th>Unit Cost </th>
                    <th>Amount </th>
                    <th>Given By </th>
                    <th>Remarks</th>
                  </tr>
                  {data?.documents?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item?.complimentaryDetails?.length > 0 ? (
                        item?.complimentaryDetails.map((comp, compIndex) => (
                          <tr key={compIndex}>
                            <td>{comp?.name}</td>
                            <td>{comp?.items}</td>
                            <td>{comp?.quantity}</td>
                            <td>{comp?.unitCost}</td>
                            <td>
                              {calculateCurrencyTotal(
                                comp?.quantity,
                                comp?.unitCost
                              ).toFixed(2) || 0}
                            </td>
                            <td>{comp?.givenBy}</td>
                            <td>{comp?.remarks}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">No data</td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

    {data?.dublicate?.length > 0 && (
        data?.dublicate?.map((data, index) => (
            data?.documents?.length > 0 &&(
  
       
         <div className="saledetails">
         <div className="content">
           <div className="contentwrapper">
             <div className="button">
               <button
                 className="btt"
                 onClick={() => {
                   handleEdit(data?._id?.counterName, data);
                 }}
                 style={{
                   display:
                     employeeType === "Financial Manager" ? "none" : "block",
                 }}
               >
                 {" "}
                 Edit
               </button>
             </div>
 
             {data?.documents?.[0]?.ticketDetails?.length > 0 && (
               <div className="ticketdetails">
                 <h2> Ticket details:- </h2>
 
                 <table>
                   <tr>
                     {/* <th>Emp. Name</th> */}
                     <th>Combo Type</th>
                     <th>Band Colour</th>
                     <th>Opening</th>
                     <th>Closing</th>
                     <th>Damage</th>
                     <th>Ext. Ticket</th>
                     <th>Compliment</th>
                     <th>Compliment By</th>
 
                     {/* <th>Annual Pass</th> */}
                     <th>Used Bands</th>
                     <th>Total Bands</th>
                   </tr>
                   {data?.documents?.map((item, index) => (
                     <React.Fragment key={index}>
                       {item?.ticketDetails?.length > 0 ? (
                         item?.ticketDetails.map((ticket, ticketIndex) => (
                           <tr key={ticketIndex}>
                             {/* <td> {item?.posname}</td> */}
                             <td>{ticket?.comboType}</td>
 
                             <td>
                               {ticket?.bandColour.charAt(0).toUpperCase() +
                                 ticket?.bandColour.slice(1)}
                             </td>
                             <td>{ticket?.ticketOpeningNo}</td>
                             <td>{ticket?.ticketClosingNo}</td>
                             <td>{ticket?.damage}</td>
                             <td>{ticket?.extendedTicket}</td>
                             <td>{ticket?.totalComplimentary}</td>
                             <td>{ticket?.complimentBy}</td>
 
                             {/* <td>{ticket?.annualPass}</td> */}
                             <td>{ticket?.totalUsedBands}</td>
                             <td>{ticket?.totalBands}</td>
                           </tr>
                         ))
                       ) : (
                         <tr>
                           <td colSpan="12">No data</td>
                         </tr>
                       )}
                     </React.Fragment>
                   ))}
                 </table>
               </div>
             )}
             <div className="saledetailsreports">
               <div className="cashflow">
                 <h2>
                   Cash flow Details:-
                   {data?.documents?.map((itemcash, index) => {
                     return (
                       <span style={{ fontWeight: 400 }}>
                         {itemcash?.posname}
                         {", "}
                       </span>
                     );
                   })}
                 </h2>
                 <table>
                   <tr>
                     <th>Currency Type</th>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <th>No. of Notes </th>
                           <th>Total</th>
                         </>
                       );
                     })}
                   </tr>
 
                   <tr>
                     <td>500</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes500}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes500,
                               500
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>200</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes200}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes200,
                               200
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>100</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes100}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes100,
                               100
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>50</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes50}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes50,
                               50
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>20</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes20}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes20,
                               20
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>10</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes10}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes10,
                               10
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>5</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes5}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes5,
                               5
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>2</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes2}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes2,
                               2
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>1</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td>{itemcash?.cashFlowDetails?.notes1}</td>
                           <td>
                             {calculateCurrencyTotal(
                               itemcash?.cashFlowDetails?.notes1,
                               1
                             ) || 0}
                           </td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>Cash</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td></td>
                           <td>{itemcash?.cashFlowDetails?.cash}</td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>Upi</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td></td>
                           <td>{itemcash?.cashFlowDetails?.upi}</td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>Razorpay</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td></td>
                           <td>{itemcash?.cashFlowDetails?.razorpay || 0}</td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>voucher</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td></td>
                           <td>{itemcash?.cashFlowDetails?.voucher || 0}</td>
                         </>
                       );
                     })}
                   </tr>
                   <tr>
                     <td>Grand Total</td>
                     {data?.documents?.map((itemcash, index) => {
                       return (
                         <>
                           <td></td>
                           <td>{itemcash?.cashFlowDetails?.totalCash}</td>
                         </>
                       );
                     })}
                   </tr>
                 </table>
               </div>
               {data?.documents?.[0]?.onlineDeliveryOrder.length > 0 && (
                 <div className="partydetails">
                   <h2>Online Delivery Order:-</h2>
                   <table>
                     <tr>
                       <th>Invoice</th>
 
                       <th>Amount</th>
                       <th>Aggregator </th>
                       <th>Remarks</th>
                     </tr>
 
                     {data?.documents?.[0]?.onlineDeliveryOrder?.map(
                       (online, indexes) => {
                         return (
                           <tr key={indexes}>
                             <td>{online?.invoice}</td>
                             <td>{online?.amount}</td>
                             <td>{online?.aggregator}</td>
                             <td>{online?.remarks}</td>
                           </tr>
                         );
                       }
                     )}
                   </table>
                 </div>
               )}
             </div>
 
             {data?.documents?.[0]?.complimentaryDetails?.length > 0 && (
               <div className="ticketdetails">
                 <h2> Complimentary Details:- </h2>
 
                 <table>
                   <tr>
                     <th>Name</th>
                     <th>Items</th>
                     <th>Quantity</th>
                     <th>Unit Cost </th>
                     <th>Amount </th>
                     <th>Given By </th>
                     <th>Remarks</th>
                   </tr>
                   {data?.documents?.map((item, index) => (
                     <React.Fragment key={index}>
                       {item?.complimentaryDetails?.length > 0 ? (
                         item?.complimentaryDetails.map((comp, compIndex) => (
                           <tr key={compIndex}>
                             <td>{comp?.name}</td>
                             <td>{comp?.items}</td>
                             <td>{comp?.quantity}</td>
                             <td>{comp?.unitCost}</td>
                             <td>
                               {calculateCurrencyTotal(
                                 comp?.quantity,
                                 comp?.unitCost
                               ).toFixed(2) || 0}
                             </td>
                             <td>{comp?.givenBy}</td>
                             <td>{comp?.remarks}</td>
                           </tr>
                         ))
                       ) : (
                         <tr>
                           <td colSpan="12">No data</td>
                         </tr>
                       )}
                     </React.Fragment>
                   ))}
                 </table>
               </div>
             )}
           </div>
         </div>
       </div>
            )
        ))
    )}

    </div>
  );
};

export default SaleDetailsDublicate;
